import React from "react"
import { css } from "@emotion/core"
import { navigate } from "gatsby"

import { weights, h1L } from "../styles"
import Layout from "../components/layout"
import Button from "../components/Button"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout
    headerData={{
      height: "calc(100vh - 200px)",
      mobileMinHeight: "93vh",
      children: (
        <div
          css={css`
            font-weight: ${weights.light};
            text-align: center;
          `}
        >
          <h1 css={h1L}>このページはご利用いただけません</h1>
          <p>リンクに問題があるか、ページが削除された可能性があります。</p>
          <br />
          <Button onClick={() => navigate("/")}>トップページに戻る</Button>
        </div>
      ),
    }}
  >
    <SEO title="404: Not found" />
  </Layout>
)

export default NotFoundPage
